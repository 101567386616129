<template>
  <div>
    <div style="margin: 15px 0">
      <a-input-search v-model="sValue" placeholder="回车搜索" @search="search" @keydown.enter="search"
                      style="width: 20%"/>
    </div>
    <!--    <div style="margin:10px 0">-->
    <!--      <a-input placeholder="回车搜索" @keydown.enter="search" v-model="sValue"/>-->
    <!--    </div>-->
    <div>

      <a-table
          ref="table"
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.id"
          :pagination="false"

      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <!--        eslint-disable-next-line vue/no-unused-vars -->
        <template slot="operation" slot-scope="text, record">

          <router-link :to="`/BackEnd/EditHelper/${record.id}`">编辑 / 删除</router-link>

        </template>

      </a-table>
    </div>
    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="!isSearch"
                  :default-current="1"
                  v-model="curr"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />
    <a-pagination style="float: right;margin-top: 15px;"
                  v-if="isSearch"
                  :default-current="1"
                  v-model="currSearch"
                  :pageSize="12"
                  :total="this.returnBack.count"
    />

  </div>

</template>


<script>
export default {
  name: "HelperCenter",
  data() {
    return {
      isSearch: false,
      currSearch: 1,
      curr: 1,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '标题',
          dataIndex: 'posts_name',
          key: 'posts_name',
          align: "center",
          ellipsis: "true",
        },

        {
          title: '文章权重',
          dataIndex: 'index',
          key: 'index',
        },
        {
          title: '模块位置',
          dataIndex: 'type',
          key: 'type',
          align: "center",

        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
          align: "center",

        },
        {
          title: '最后更新时间',
          dataIndex: 'update_time',
          key: 'update_time',
          align: "center",

        },

        {
          title: 'operation',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
        },
      ],
      data: [],
      returnBack: [],
      sValue: ''
    }
  },
  watch: {
    currSearch: {
      async handler(n) {
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Helps/searchHelps', {
          "page": n,
          "pageCount": 12,
          "posts_name": this.sValue
        })).data.data)
        this.data = []
        this.isSearch = true
        this.returnBack.list.forEach(v => {
          this.data.push({
            ...v, type: v.type != 0 ? '帮助中心' : '更新介绍'
          })
        })
      }
    },
    curr: {
      async handler(n) {
        this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Helps/getHelpsList', {
          "page": n,
          "pageCount": 12
        })).data.data)
        this.data = []
        this.returnBack.list.forEach(v => {
          this.data.push({
            ...v, type: v.type != 0 ? '帮助中心' : '更新介绍'
          })
        })
      }
    },
  },
  methods: {
    async search() {
      this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Helps/searchHelps', {
        "page": 1,
        "pageCount": 12,
        "posts_name": this.sValue
      })).data.data)
      this.data = []
      this.currSearch = 1

      this.isSearch = true
      this.returnBack.list.forEach(v => {
        this.data.push({
          ...v, type: v.type != 0 ? '帮助中心' : '更新介绍'
        })
      })
    }
  },

  async created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.38'
    this.returnBack = JSON.parse((await this.$axios.post('/Admin/Api/Helps/getHelpsList', {
      "page": 1,
      "pageCount": 12
    })).data.data)
    this.returnBack.list.forEach(v => {
      this.data.push({
        ...v, type: v.type != 0 ? '帮助中心' : '更新介绍'
      })
    })

  }
}

</script>

<style scoped>

</style>
